<template>
  <RuesHeaderForMagazin
    v-if="['urlaubsmagazin', 'vermieter'].includes(contentGroup)"
    :content-group="contentGroup"
  />
  <RuesHeaderForRuesPage
    v-if="props.pagedata.urldata.pagetype_data.type === 'rues_page'"
    :header-details="props.pagedata.details?.header"
  />
  <RuesHeaderForHolidayPage
    v-if="props.pagedata.details?.header && props.pagedata.urldata.pagetype_data.type === 'holiday_page'"
    :header-details="props.pagedata.details?.header"
  />
  <!-- Wrapper for the first two containers if contentGroup is a special one -->
  <div v-if="isSpecialContentGroup" class="container 2xl:max-w-screen-xl mx-auto px-6 mb-5 mt-12 text-left grid grid-cols-1 xl:grid-cols-3 gap-4">
    <div v-for="(ruesContainer, index) in rearrangedContainers" :key="ruesContainer.id" :class="{'col-span-2': index === 0, 'col-span-1 xl:ml-5': index === 1}">
      <div class="grid grid-cols-12 gap-x-4 gap-y-8" :class="{'sticky top-1': index === 1}">
        <template v-for="ruesElement in ruesContainer.elements" :key="ruesElement.id">
          <RuesComponents :sidebar="index === 1" :special-container="index === 0" :rues-element="ruesElement" :search-params="props.pagedata.details?.header?.search_element?.data?.params" />
        </template>
      </div>
    </div>
  </div>

  <div v-for="ruesContainer in (isSpecialContentGroup ? props.pagedata.details.containers.slice(2) : props.pagedata.details.containers)" :key="ruesContainer.id" class="container 2xl:max-w-screen-xl mx-auto grid grid-cols-12 gap-x-4 gap-y-8 px-6 mb-5 mt-12 text-left">
    <UiHeadline v-if="ruesContainer.data?.header" type="h2" design="h1" class="col-span-12">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="ruesContainer.data?.header" />
      <!-- eslint-enable vue/no-v-html -->
    </UiHeadline>
    <template v-for="ruesElement in ruesContainer.elements" :key="ruesElement.id">
      <RuesComponents :rues-element="ruesElement" :search-params="props.pagedata.details?.header?.search_element?.data?.params" />
    </template>
  </div>
</template>

<script setup>
const props = defineProps({
  pagedata: {
    type: Object,
    default: null
  }
})

const contentGroupsMapping = {
  Urlaubsmagazin: 'urlaubsmagazin',
  Inselmagazin: 'inselmagazin',
  Urlaubsziele: 'urlaubsziele',
  Inspiration: 'inspiration',
  Vermietermagazin: 'vermieter',
  'Urlaubsziele mit Sidebar': 'magazin'
}

const pagetype = props.pagedata.urldata.pagetype_data.type
let contentGroup = ''

if (pagetype === 'holiday_page') {
  const headerBereich = props.pagedata.details.header.bereich
  contentGroup = contentGroupsMapping[headerBereich] || ''
} else if (pagetype === 'rues_page') {
  contentGroup = props.pagedata.urldata.path === '/' ? 'homepage' : 'rues'
}
const isSpecialContentGroup = computed(() => ['inselmagazin', 'urlaubsmagazin', 'vermieter', 'magazin'].includes(contentGroup))

const rearrangedContainers = [...props.pagedata.details.containers.slice(0, 2)]
if (isSpecialContentGroup.value && rearrangedContainers.length === 2) {
  rearrangedContainers.reverse()
}

if (import.meta.client && contentGroup !== '') {
  window.dataLayer.unshift({ content_group: contentGroup })
}

// TODO: Send cache-control headers for CloudFront
// const nuxtApp = useNuxtApp()
// if (process.server) {
//   // https://github.com/nuxt/nuxt/discussions/25425
//   nuxtApp.ssrContext.event.node.res.setHeader('cache-control', 'max-age=0, s-maxage=3600, stale-while-revalidate=600, stale-if-error=86400')
// }
</script>
