<template>
  <RuesBoxSize v-if="props.item?.reviews.length !== 0" :size="props.item?.width" class="text-left grid grid-cols-1 gap-x-4 gap-y-4 product-carousel">
    <UiHeadline v-if="props.item?.headline != ''" type="h2" design="h2">
      {{ props.item?.headline }}
    </UiHeadline>
    <UiHeadline v-else type="h2" design="h2">
      <UiLanguagePlaceholder domain="RuesProductReviews" name="headline" :variables="{ region : props.item?.region, preposition : props.item?.preposition }" />
    </UiHeadline>
    <div v-if="!loaded" class="flex flex-nowrap gap-x-4 w-full overflow-hidden py-3">
      <SkeletonSharedObjectCardWithReviews class="w-[92%] md:w-[41.7%] lg:w-[33.3333%]" />
      <SkeletonSharedObjectCardWithReviews class="md:w-[41.7%] lg:w-[33.3333%] hidden md:block" />
      <SkeletonSharedObjectCardWithReviews class="lg:w-[33.3333%] hidden lg:block" />
      <SkeletonSharedObjectCardWithReviews :is-half="true" class="w-[8%] md:w-[12.2%] block lg:hidden" />
    </div>
    <div v-show="loaded" class="relative">
      <swiper
        :modules="modules"
        :breakpoints="{
          '1024': {
            slidesPerView: 3,
            spaceBetween: 1,
            allowTouchMove: false,
            navigation: false
          },
          '768': {
            slidesPerView: 2.1,
            spaceBetween: 10
          }
        }"
        :slides-per-view="1.1"
        :space-between="0"
        direction="horizontal"
        :lazy="true"
        class="h-full w-full"
        @swiper="onSwiper"
        @slide-change="onSlideChange"
      >
        <swiper-slide v-for="review in props.item?.reviews" :key="review.id" class="w-full px-2 py-3">
          <LazySharedObjectCardWithReviews :review="review" />
        </swiper-slide>
      </swiper>
      <div v-if="!isDesktop && !isBeginning" class="swiper-button-prev" @click.stop.prevent="swiperRef.slidePrev()" />
      <div v-if="!isDesktop && !isEnd" class="swiper-button-next" @click.stop.prevent="swiperRef.slideNext()" />
    </div>
  </RuesBoxSize>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import { Navigation } from 'swiper/modules'
const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})
const loaded = ref(false)
const swiperRef = ref()
const isBeginning = ref(true)
const isEnd = ref(false)
const isDesktop = ref(false)

const onSwiper = (swiper) => {
  swiperRef.value = swiper
}
const onSlideChange = (swiper) => {
  isBeginning.value = swiper.isBeginning
  isEnd.value = swiper.isEnd
}
const modules = [Navigation]

const updateIsDesktop = () => {
  isDesktop.value = window.innerWidth >= 1024
}

onMounted(() => {
  updateIsDesktop()
  window.addEventListener('resize', updateIsDesktop)
  loaded.value = true
})

onUnmounted(() => {
  window.removeEventListener('resize', updateIsDesktop)
})
</script>

<style src="./scss/RuesProductCarousel.scss" scoped></style>
